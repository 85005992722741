.overlay
{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 99;
}
.confirmAlert {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    width: 320px;
    background-color: #fff;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
    img {
      margin-top: 20px;
    }
    h4 {
      color: #ec5b56;
    }
    p {
      font-size: 13px;
      color: #000;
      margin-bottom: 20px;
      padding: 0 28px;
    }
    button {
      width: 50% !important;
      display: inline-block;
      border: 0 !important;
      height: 42px !important;
      color: #fff !important;
      border-radius: 0 !important;
      cursor: pointer;
    }
    .cancelBtn {
      background-color: #ec5b56;
    }
    .confirmBtn {
      background-color: #086284;
    }
  }
  

// toast custom styling
.customToastStyle
{
  top: 72px !important;
  max-width: 500px;
  position: relative;
  z-index: 2023 !important;
  .MuiAlert-root
  {  
    padding: 15px 18px;
    padding-left: 60px;  
  }  
  .MuiAlert-filledError
  {
    background: linear-gradient(90deg, #FD313D 0%, #B00437 100%);
  }
  .MuiAlert-filledWarning
  {
    background: linear-gradient(90deg, #FFC806 0%, #FC9A07 100%);
  }
  .MuiAlert-filledInfo
  {
    background: linear-gradient(90deg, #6BCDF8 0%, #008EAD 100%);
  }
  .MuiAlert-filledSuccess
  {
    background: linear-gradient(90deg, #30F2C5 0%, #08CE83 100%);
  }
  .MuiAlert-icon
  {
    display: none;
  }
  .toastMsg
  {
    vertical-align: 0px;
  }
  .MuiAlert-message
  {
    line-height: 18px;
    color: #fff;
  }
  .iconSetSize img
  {
      width: 26px;
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
  }
}
// toast custom styling

// loader styling
.load
{
    position: absolute;   
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 99;
    img
    {
        position: absolute;   
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }
}
// loader styling


// empty card styles
.emptyCard
{
    background-color: #fff;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    h5
    {
        text-align: center;
    }
}

// empty card styles
// import dialogue
.importAlert {
  width: 398px;
  height: 286px;
  left: 647px;
  top: 128px;
  position: fixed;
  background: linear-gradient(180deg, #69ccf6 0%, #028faf 100%);
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  top: 40%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 100;
  width: 420px;
  background-color: #fff;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.25);
  h4 {
    color: #fff;
    font-size: 14px;
  }
  p {
    font-size: 12px;
    color: #fff;
    margin-bottom: 20px;
    padding: 0px 80px;
  }
  .right {
    margin-left: 85%;
    margin-top: 10px;
  }
}
.save-button {
  font-weight: 500;
  color: #fff;
  width: auto;
  border: 0;
  margin:15px 0px;
  font-size: 14px;
  padding: 5px 20px;
  border-radius: 8px;
  transition: 0.3s;
  cursor: pointer;
  height: 34px;
  background-color: #086284;
box-shadow: none;

  &:hover {
    cursor: pointer;
    background-color: #086284;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.4;
  }

  &:focus-visible {
    outline: black auto 1px;
    outline-offset: 2px;
  }

  &:active {
    transform: translateY(1px);
  }
}